@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 20 14.3% 4.1%;
        --card: 0 0% 100%;
        --card-foreground: 20 14.3% 4.1%;
        --popover: 0 0% 100%;
        --popover-foreground: 20 14.3% 4.1%;
        --primary: 24 9.8% 10%;
        --primary-foreground: 60 9.1% 97.8%;
        --secondary: 60 4.8% 95.9%;
        --secondary-foreground: 24 9.8% 10%;
        --muted: 60 4.8% 95.9%;
        --muted-foreground: 25 5.3% 44.7%;
        --accent: 60 4.8% 95.9%;
        --accent-foreground: 24 9.8% 10%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 60 9.1% 97.8%;
        --border: 20 5.9% 90%;
        --input: 20 5.9% 90%;
        --ring: 20 14.3% 4.1%;
        --radius: 0.5rem;
        --chart-1: 12 76% 61%;
        --chart-2: 173 58% 39%;
        --chart-3: 197 37% 24%;
        --chart-4: 43 74% 66%;
        --chart-5: 27 87% 67%;
        --gradient-color-light: rgba(0, 0, 0, 0.1);
        --gradient-color-dark: rgba(255, 255, 255, 0.1);
    }
    .dark {
        --background: 20 14.3% 4.1%;
        --foreground: 60 9.1% 97.8%;
        --card: 20 14.3% 4.1%;
        --card-foreground: 60 9.1% 97.8%;
        --popover: 20 14.3% 4.1%;
        --popover-foreground: 60 9.1% 97.8%;
        --primary: 60 9.1% 97.8%;
        --primary-foreground: 24 9.8% 10%;
        --secondary: 12 6.5% 15.1%;
        --secondary-foreground: 60 9.1% 97.8%;
        --muted: 12 6.5% 15.1%;
        --muted-foreground: 24 5.4% 63.9%;
        --accent: 12 6.5% 15.1%;
        --accent-foreground: 60 9.1% 97.8%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 60 9.1% 97.8%;
        --border: 12 6.5% 15.1%;
        --input: 12 6.5% 15.1%;
        --ring: 24 5.7% 82.9%;
        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
        --gradient-color-light: rgba(255, 255, 255, 0.1);
        --gradient-color-dark: rgba(0, 0, 0, 0.1);
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-synthesis-weight: none;
    text-rendering: optimizeLegibility;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    @apply font-sans antialiased select-none;
}

#root {
    @apply flex flex-col h-screen;
}

.nav-item {
    @apply rounded-md px-3 py-2 text-gray-700 hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700;
}

button,
.btn {
    @apply font-bold py-2 px-4 transition;
}

.primary {
    @apply bg-[#2e2f30] hover:bg-[#5a6e71] active:bg-[#2e2f30] text-white rounded-md shadow dark:bg-gray-800 dark:hover:bg-gray-700 dark:active:bg-gray-800;
}

.secondary {
    @apply bg-[#5a6e71] hover:bg-[#9aaeac] active:bg-[#5a6e71] text-white rounded-md shadow dark:bg-gray-700 dark:hover:bg-gray-600 dark:active:bg-gray-700;
}

.tertiary {
    @apply text-gray-800 hover:text-gray-500 active:text-gray-800 dark:text-gray-300 dark:hover:text-gray-400 dark:active:text-gray-300;
}

inputBIS {
    @apply mt-2 shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring dark:text-gray-300 dark:bg-gray-800 dark:border-gray-700;
}

select {
    @apply shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring dark:text-gray-300 dark:bg-gray-800 dark:border-gray-700;
}

labelBIS {
    @apply block text-gray-700 text-sm font-bold mb-2 dark:text-gray-300;
}

.container {
    @apply flex flex-col gap-1 rounded-2xl border shadow p-4 m-auto w-1/2;
}

/*REGISTER*/
.register input {
    @apply mt-1;
}

#gradient {
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 25%, rgba(22, 163, 74, 1) 62%, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 1) 100%);
}

#g1 {
    background-image: url('../public/bgx.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#g12 {
    @apply relative;
}

#g12::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;
    z-index: -1;
    background: url('../public/g1.png') 0 0 repeat;
    transform: rotate(90deg);
}

table {
    @apply w-full border-collapse border rounded-md overflow-hidden shadow bg-muted select-none border-secondary;
}

th {
    @apply h-10 bg-primary font-bold text-primary-foreground;
}

td {
    @apply border border-border dark:border-neutral-700;
}

/*
th, td {
    @apply border h-10 border-primary-foreground bg-primary-foreground text-primary;
}
 */

.invalid-row,
.invalid-column {
    background-color: red;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        /*transform: translateY(20px);*/
        /*transform: translateY(20px);*/
    }
    99% {
        opacity: 0.99;
        /*transform: translateY(0);*/
        /*transform: translateY(20px);*/
    }
    100% {
        opacity: 1;
        /*transform: translateY(0);*/
    }
}

.tableau {
    animation: fadeIn .5s ease-in-out forwards;
    /*transition: transform 0.3s ease-in-out;*/
}

.tableau:hover {
    transform: scale(1.05) rotate(1.5deg);
}

.gradient-icon {
    background: linear-gradient(to right, purple, blue, pink);
    -webkit-background-clip: text;
    color: transparent;
}

.billing-table td {
    text-align: center;
}

.description-text {
    opacity: 1;
}

.transition-max-height {
    transition: max-height 0.5s ease-in-out;
}

.dynamic-container {
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    overflow: hidden;
    display: inline-block;
}

.landing-page {
  @apply relative w-full h-full bg-gray-900 dark:bg-white;
  background: linear-gradient(90deg, var(--gradient-color-light) 1px, transparent 1px),
              linear-gradient(var(--gradient-color-light) 1px, transparent 1px);
  background-size: 20px 20px;
}

.dark .text-halo {
  text-shadow: 0 0 5px white, 0 0 10px white, 0 0 15px white;
}

.halo {
  text-shadow: 0 0 5px white, 0 0 10px white, 0 0 15px white;
}

.cloud-element {
  width: fit-content;
  height: fit-content;
  background: radial-gradient(circle, rgba(25, 25, 25, 0.9) 0%, rgba(25, 25, 25, 0) 50%);
  border-radius: 5%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#plazza {
  z-index: 1000 !important;
}
